import {Injectable} from '@angular/core';
import {SidenavService} from '../index';
import {SIDENAV_CONTENT_MAP} from '../../../models';
import {SessionStorageService} from '../session-storage.service';

@Injectable({providedIn: 'root'})
export class StateStorageService {
  private previousUrlKey = 'previousUrl';
  private previousSideNavContent = 'sideNavContent';

  constructor(
    private sidenavService: SidenavService,
    private sessionStorageService: SessionStorageService
  ) {
  }

  storeUrl(url: string): void {
    this.sessionStorageService.setItem(this.previousUrlKey, url);
  }

  getUrl(): string | null | undefined {
    return this.sessionStorageService.getItem(this.previousUrlKey);
  }

  clearUrl(): void {
    this.sessionStorageService.removeItem(this.previousUrlKey);
  }

  backToPrevSideNav(): boolean {
    const prevsdnv = this.getSidenav();
    if (prevsdnv) {
      this.sidenavService.changeContent({content: SIDENAV_CONTENT_MAP[prevsdnv], open: true});
      this.clearSidenav();
      return true;
    }
    return false;
  }

  storeSidenav(url: string): void {
    this.sessionStorageService.setItem(this.previousSideNavContent, url);
  }

  getSidenav(): string | null | undefined {
    return this.sessionStorageService.getItem(this.previousSideNavContent);
  }

  clearSidenav(): void {
    this.sessionStorageService.removeItem(this.previousSideNavContent);
  }
}
