import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {APP_TITLE_LANG} from './app.constants';
import {LanguageService, SidenavService, TranslationService} from './core/services';
import {AppParametersService} from './core/services/app-parameters.service';
import {Principal} from './core/services/auth/principal.service';
import {LocalStorageService} from './core/services/local-storage.service';
import {PlatformService} from './core/services/platform.service';
import {SeoMetaService} from './core/services/seo-meta.service';
import {WatchService} from './core/services/watch.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private translationService: TranslationService,
    private languageService: LanguageService,
    private appParametersService: AppParametersService,
    private sideNavService: SidenavService,
    private principal: Principal,
    private snackBar: MatSnackBar,
    private router: Router,
    private localStorageService: LocalStorageService,
    private platformService: PlatformService,
    private watchService: WatchService,
    private seoMetaService: SeoMetaService,
    private metaService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngAfterViewInit(): void {
    const language = this.languageService.getLanguage();
    const title = `${APP_TITLE_LANG[language?.locale]}`;
    this.seoMetaService.updateMetas({title, setCanonical: false});
  }


  ngOnInit(): void {
    this.translationService.loadTranslations();
    this.appParametersService.loadSettings();
    this.watchPrincipal();
    this.watchService.watchOrdersToPrint();
  }

  watchPrincipal(): void {
    this.principal.identity(true).then(() => {
      this.checkTokenParam();
    });
  }

  private checkTokenParam() {
    if (!this.platformService.isPlatformBrowser()) {
      return;
    }
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const error = url.searchParams.get('error');
    if (token && !this.localStorageService.getItem(token)) {
      this.localStorageService.setItem('token', token);
      setTimeout(() => {
        this.router.navigate([`/${this.languageService.getLanguage()?.locale}`]).then();
        this.sideNavService.openSideNav();
        this.sideNavService.toProfile();
      }, 200);
    }
    if (error) {
      this.snackBar.open('ERROR', error, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
  }
}
