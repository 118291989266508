import {Injectable} from '@angular/core';
import {SERVER_API_URL, SSR} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppParameter, KPaging, PageResponse} from '../../models';
import {IDatasourceService} from './k-datasource.datasource';
import {HttpUtils} from './httpUtils';
import {tap} from 'rxjs/operators';
import {SsrService} from './ssr.service';

const BASE_SETTINGS_URL = SERVER_API_URL + '/settings';


@Injectable({
  providedIn: 'root'
})
export class AppParametersService implements IDatasourceService<AppParameter> {
  private settings: any;

  constructor(private http: HttpClient, private ssrService: SsrService) {
    this.loadSettings().subscribe(() => {
      console.log('********Settings Loaded********');
    });
  }

  update(appSettings: AppParameter): Observable<any> {
    return this.http.post(BASE_SETTINGS_URL, appSettings);
  }

  search(paging: KPaging, filter: any): Observable<PageResponse<AppParameter>> {
    return this.http.get<PageResponse<AppParameter>>(`${BASE_SETTINGS_URL}/search`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }

  loadSettings(): Observable<any> {
    return this.ssrService.usingTransferState(SSR.settings.GET_ALL.key, () => {
      return this.http.get(BASE_SETTINGS_URL)
        .pipe(
          tap(resp => {
            this.settings = resp;
          })
        );
    });
  }

  getSetting(key: string): AppParameter {
    if (!this.settings) {
      this.loadSettings();
      return null;
    }
    return this.settings[key];
  }

}
