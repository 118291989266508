import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User, UserRole} from '../../../models';
import {AccountService} from '../index';
import {LocalStorageService} from '../local-storage.service';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class Principal {
  private userIdentity: User;
  private authenticated = false;
  private authenticationState = new Subject<User>();

  constructor(
    private _account: AccountService,
    private _localStorage: LocalStorageService,
    private _router: Router
  ) {
  }

  authenticate(identity) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  isSuperAdmin(): Promise<boolean> {
    return this.getUser().then(user => {
      return Promise.resolve(user && user.role === UserRole.SUPER_ADMIN);
    }, fail => {
      return Promise.resolve(false);
    });
  }

  isAdmin(): Promise<boolean> {
    return this.getUser().then(user => {
      return Promise.resolve(user && (user.role === UserRole.ADMIN || this.isSuperAdmin()));
    }, fail => {
      return Promise.resolve(false);
    });
  }

  getUser(): Promise<User> {
    if (!this._localStorage.getItem('token')) {
      this.authenticate(null);
      this._router.navigate(['']).then(() => {
        console.log('Navigated Successfully');
      }, reason => console.log(`Navigate failed: ${reason}`));
      return Promise.reject('not Authorized');
    } else if (!this.userIdentity) {
      return this._account.getCurrent()
        .pipe(
          tap(user => {
            this.userIdentity = user;
          })
        )
        .toPromise();
    }
    return Promise.resolve(this.userIdentity);
  }

  identity(force?: boolean): Promise<User> {
    if (force) {
      this.userIdentity = undefined;
    }
    if (!this._localStorage.getItem('token')) {
      return Promise.resolve(null);
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    return this._account
      .getCurrent()
      .toPromise()
      .then(user => {
        if (user) {
          this.userIdentity = user;
          this.authenticated = true;
        } else {
          this.userIdentity = null;
          this.authenticated = false;
        }
        this.authenticationState.next(this.userIdentity);
        return this.userIdentity;
      })
      .catch(() => {
        this.userIdentity = null;
        this.authenticated = false;
        this.authenticationState.next(this.userIdentity);
        return null;
      });
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  hasAnyAuthority(authorities: string[]): Promise<boolean> {
    return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
  }

  hasAnyAuthorityDirect(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.role) {
      return false;
    }
    return authorities.includes(this.userIdentity.role);
  }
}
