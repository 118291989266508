import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MIDDLE_SERVER_URL, SERVER_API_URL} from '../../app.constants';
import {LocalStorageService} from '../services/local-storage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private localStorage: LocalStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url)
      && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL))
      && !(MIDDLE_SERVER_URL && request.url.startsWith(MIDDLE_SERVER_URL))
    )) {
      return next.handle(request);
    }
    console.log(`********  ${request.url} *************`);

    const token = this.localStorage.getItem('token');
    if (!!token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    return next.handle(request).pipe(catchError((error) => {
      return throwError(error);
    }));
  }
}
