import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {IProductType, KPaging, PageResponse, ProductType} from '../../models';
import {HttpUtils} from './httpUtils';
import {IDatasourceService} from './k-datasource.datasource';
import {SsrService} from './ssr.service';

const BASE_ACC_URL = SERVER_API_URL + '/product-type';


@Injectable({
  providedIn: 'root'
})
export class ProductTypeService implements IDatasourceService<ProductType>, OnDestroy {
  private selectedProdTypeSubj = new BehaviorSubject<IProductType>(null);
  public selectedProdType$ = this.selectedProdTypeSubj.asObservable();

  constructor(private http: HttpClient, private ssrService: SsrService) {
  }

  public pushSelectedProductType(prodType: IProductType) {
    this.selectedProdTypeSubj.next(prodType);
  }

  add(productType: IProductType) {
    return this.http.post(`${BASE_ACC_URL}/add`, productType);
  }

  find(id: number): Observable<ProductType> {
    return this.http.get<ProductType>(`${BASE_ACC_URL}/${id}`);
  }

  delete(id: number) {
    return this.http.post(`${BASE_ACC_URL}/delete/${id}`, {});
  }

  updateProductPriority(productTypeId: number, priority: number): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/priority`, {productTypeId, priority});
  }

  getAll(): Observable<ProductType[]> {
    return this.ssrService.usingTransferState('PRODUCT_TYPES_ALL',
      () => this.http.get<ProductType[]>(`${BASE_ACC_URL}/all`)
    );
  }

  search(paging: KPaging, filter: any): Observable<PageResponse<ProductType>> {
    return this.http.get<PageResponse<ProductType>>(`${BASE_ACC_URL}/page`, {params: HttpUtils.getHttpParams(paging, filter)});
  }

  ngOnDestroy(): void {
    this.selectedProdTypeSubj.complete();
  }
}
