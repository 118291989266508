export class Login {

  constructor(
    public login?: string,
    public password?: string,
  ) {

  }

}
