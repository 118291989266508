export enum SidenavContent {
  CART = 'cart',
  LOGIN = 'login',
  SIGNUP = 'sign_up',
  USER_PROFILE = 'profile',
  CHECKOUT = 'checkout',
}

export const SIDENAV_CONTENT_MAP = {
  cart: SidenavContent.CART,
  login: SidenavContent.LOGIN,
  sign_up: SidenavContent.SIGNUP,
  profile: SidenavContent.USER_PROFILE,
  checkout: SidenavContent.CHECKOUT,

};

export interface ISidenavModel {
  content?: SidenavContent;
  open?: boolean;
}

export class SidenavModel implements ISidenavModel {
  constructor(
    public content?: SidenavContent,
    public open?: boolean
  ) {
  }
}
