export class User {

  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public address?: string,
    public phone?: string,
    public role?: string,
    public password?: string,
    public confirmPassword?: string
  ) {

  }

}
