export interface IProductDetail {
  id?: number;
  name?: string;
  price?: number;
  checked?: boolean;
}

export class ProductDetail {
  constructor(
    public id?: number,
    public name?: string,
    public price?: number,
    public checked?: boolean,
  ) {
  }

}
