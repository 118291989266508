import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

const ACTIONS = [
  {name: 'Confirm', id: 'CONFIRM'},
  {name: 'Cancel', id: 'CANCEL'}
];

export enum DIALOG_TYPE {
  CONFIRM,
  NOTIFICATION,
  ERROR,
  SUCCESS,
}

export interface Action {
  name: string;
  id: string;
}

export interface Input {
  name: string;
  type: string;
  required: boolean;
  value: any;
}

export interface DialogData {
  title: string;
  text: string;
  type: DIALOG_TYPE;
  actions: Action[];
  inputs: Input[];
}


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onActionClick(event, action): void {
    const inputValues = {};
    let valid = true;
    if (this.data.inputs) {
      this.data.inputs.forEach(e => {
        inputValues[e.name] = e.value;
        if (e.required && !e.value) {
          valid = false;
        }
      });
    }
    if (!valid && action.id === 'CONFIRM') {
      event.stopPropagation();
      return;
    }
    this.dialogRef.close({action, value: inputValues});
  }

  ngOnInit(): void {
    if (!this.data.actions || this.data.actions.length === 0) {
      this.data.actions = ACTIONS;
    }
  }

  isConfirm() {
    return this.data.type === DIALOG_TYPE.CONFIRM;
  }

  isError() {
    return this.data.type === DIALOG_TYPE.ERROR;
  }

  isNotif() {
    return this.data.type === DIALOG_TYPE.NOTIFICATION;
  }

  getTitleClass(): string {
    switch (this.data.type) {
      case DIALOG_TYPE.ERROR:
        return 'k-d-title-error';
      case DIALOG_TYPE.SUCCESS:
        return 'k-d-title-success';
    }
  }
}
