import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, DIALOG_TYPE} from '../../shared/cofirm-dialog/confirm-dialog.component';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KDialogService {


  constructor(private dialog: MatDialog) {

  }

  openDeleteConfirmDialog(name): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {title: 'Confirm Delete', text: `Do you want to delete ${name}`, type: DIALOG_TYPE.CONFIRM}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        resolve((result && result.action.id === 'CONFIRM'));
      });
    });
  }

  openErrorDialog(title: string, text: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {title, text, type: DIALOG_TYPE.ERROR}
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 10000);
    });
  }
}
