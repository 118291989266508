import {Injectable} from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {KPaging, Order, PageResponse} from '../../models';
import {Observable} from 'rxjs';
import {IDatasourceService} from './k-datasource.datasource';
import {HttpUtils} from './httpUtils';

const BASE_ACC_URL = SERVER_API_URL + '/stats';


@Injectable({
  providedIn: 'root'
})
export class StatisticsService implements IDatasourceService<Order> {

  constructor(private http: HttpClient) {
  }


  search(paging: KPaging, filter: any): Observable<PageResponse<Order>> {
    return this.http.post<PageResponse<Order>>(`${BASE_ACC_URL}/user`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }

  getOrderStats(request: any): Observable<any> {
    return this.http.post<PageResponse<Order>>(`${BASE_ACC_URL}/order`, request);
  }

  getProductStats(request: any): Observable<any> {
    return this.http.post<PageResponse<Order>>(`${BASE_ACC_URL}/product`, request);
  }

}
