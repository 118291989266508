import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {PRINTER_URL, SERVER_API_URL} from '../../app.constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {KDialogService} from '../services';

export const EXCLUSIONS = ['/account/login', '/account/sign-up', `${PRINTER_URL}/health`];
export const EXCLUDED_STATUSES = [403];

@Injectable()
export class ErrorDialogInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private dialogService: KDialogService) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isDomesticUrl = (request.url.startsWith(SERVER_API_URL) || request.url.startsWith(PRINTER_URL));
    const isExcluded = EXCLUSIONS.filter(ex => request.url.includes(ex)).length > 0;
    if (
      !request || !request.url ||
      (
        !isDomesticUrl || isExcluded
      )
    ) {
      return next.handle(request);
    }
    return next.handle(request)
      .pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err && err.error) {
      const {error} = err;
      const message = error.detail;
      if (!EXCLUDED_STATUSES.includes(error.status)) {
        this.dialogService.openErrorDialog('notification.error', message);
      }
    }
    return throwError(err);
  }

}
