<h2 mat-dialog-title [ngClass]="getTitleClass()">{{ data.title }}</h2>
<mat-dialog-content class="mat-typography" [ngClass]="getTitleClass()">
  <p [innerHTML]="data.text"></p>
  <div class="container" *ngIf="data.inputs&&data.inputs.length>0">
    <div class="row" *ngFor="let input of data.inputs">
      <mat-form-field appearance="standard" class="flex-grow-1">
        <mat-label> {{ input.name }}</mat-label>
        <input *ngIf="input.type==='text'" matInput [type]="input.type" placeholder="Placeholder"
               [(ngModel)]="input.value">
        <textarea *ngIf="input.type==='textarea'" matInput [type]="input.type" required placeholder="Placeholder"
                  [(ngModel)]="input.value">
        </textarea>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="isConfirm()">
  <button *ngFor="let action of data.actions" class="k-button" mat-raised-button type="button"
          (click)="onActionClick($event,action)">{{ action.name }}
  </button>
</mat-dialog-actions>
