import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Language} from '../../models';
import {LanguageService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LanguageResolver implements Resolve<Language> {
  constructor(private _languageService: LanguageService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Language> {
    const selected = route.params.lang;
    return this._languageService.getAllActive()
      .pipe(
        map(languages => {
          let selectedLang;
          if (selected) {
            selectedLang = languages.find(lang => lang.locale === selected);
          }
          if (!selectedLang) {
            selectedLang = languages.find(lang => lang.isDefault);
          }
          this._languageService.setLanguage(selectedLang);
          return selectedLang;
        }));
  }
}
