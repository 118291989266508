import {Injectable} from '@angular/core';
import {AppParametersService} from './app-parameters.service';
import {ACTIVE_HOURS, DELIVERY_PRICE, ON_SPOT_FEE, PRINTER_CASH} from '../../models';
import {DEFAULT_DELIVERY_PRICE, DEFAULT_ON_SPOT_FEE} from '../../app.constants';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  activeHours: { endHour, endMins, startHour, startMins };

  constructor(private parametersService: AppParametersService) {
    this.parametersService.loadSettings().subscribe((resp) => {
      this.getActiveHours(resp[ACTIVE_HOURS]);
    });
  }

  private getActiveHours(setting) {
    if (setting) {
      const hoursString = setting.value.split('-');
      const startTime = hoursString[0].split(':');
      const endTime = hoursString[1].split(':');
      const startHour = parseInt(startTime[0], 10);
      const startMins = parseInt(startTime[1], 10);
      const endHour = parseInt(endTime[0], 10);
      const endMins = parseInt(endTime[1], 10);
      return {startHour, startMins, endHour, endMins};
    } else {
      return undefined;
    }
  }

  public checkTime(): Observable<boolean> {
    if (this.activeHours) {
      return new Observable(subscriber => {
        subscriber.next(this.isOpen(this.activeHours));
      });
    }
    return this.parametersService.loadSettings().pipe(
      tap(resp => {
        const activeHorus = resp[ACTIVE_HOURS];
        this.activeHours = this.getActiveHours(activeHorus);
        if (activeHorus) {
          return this.isOpen(this.activeHours);
        }
      })
    );
  }

  private isOpen(activeHours): boolean {
    if (activeHours?.endHour !== 0 && !activeHours?.endHour) {
      return true;
    }
    const d = new Date(new Date().toLocaleString('en-US', {timeZone: 'Asia/Tbilisi'}));
    const currentHour = d.getHours();
    const mins = d.getMinutes();
    if (activeHours.endHour > activeHours.startHour) {
      return (activeHours.endHour > currentHour || (activeHours.endHour === currentHour && mins <= activeHours.endMins))
        && (currentHour > activeHours.startHour ||
          (currentHour === activeHours.startHour && mins >= activeHours.startMins)
        );
    } else {
      return !((activeHours.endHour < currentHour || (activeHours.endHour === currentHour && mins >= activeHours.endMins))
        && (currentHour < activeHours.startHour ||
          (currentHour === activeHours.startHour && mins < activeHours.startMins))
      );
    }
  }

  public getDeliveryPrice(): number {
    const deliveryPrice = this.parametersService.getSetting(DELIVERY_PRICE);
    if (deliveryPrice) {
      return parseInt(deliveryPrice.value, 10);
    }
    return DEFAULT_DELIVERY_PRICE;
  }

  public getOnSpotFee(): number {
    const onSpotFee = this.parametersService.getSetting(ON_SPOT_FEE);
    if (onSpotFee) {
      return parseInt(onSpotFee.value, 10);
    }
    return DEFAULT_ON_SPOT_FEE;
  }

  public getPrinterName(printerType: string): string {
    const printerName = this.parametersService.getSetting(printerType);
    if (printerName) {
      return printerName.value;
    }
    return 'DEFAULT_ON_SPOT_FEE';
  }
}
