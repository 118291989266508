export const environment = {
  production: true,
  dev: false,
  test: false,
  SERVER_BASE_URL: 'https://kantora19.ge/api',
  MIDDLE_BASE_URL: 'https://kantora19.ge/middle',
  OAUTH_REDIRECT_URL: 'https://kantora19.ge',
  PRINTER_URL: 'http://localhost:8099',
  NOTIFICATION_SERVER_PUBLIC_KEY: 'BMJcT1x9HpckuAsP5nGUxfrySR1Gv2n3qyQ6wAXpoSWy98K86RkkCEsH7_8LrrkBPk3FgQss2ne7tsyDlguG8AM'
  // SERVER_BASE_URL: 'http://localhost:8080/api',
  // OAUTH_REDIRECT_URL: 'http://localhost:4200',
  // NOTIFICATION_SERVER_PUBLIC_KEY: 'BPFlQCm0x0dtgWP9CRI7LD-Kxzpl2xT3Z8PVt6KVYtKtEl3YCt6n8wbP6CmJVTKeKw5DrdGNWge_EudSKjhyOwE'
};
