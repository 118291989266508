// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application
import {environment} from '../environments/environment';
import {OrderType} from './models';

export const IS_PRODUCTION = environment.production;
export const IS_DEV = environment.dev;
export const APP_TITLE = 'Kantora 19';
export const APP_BASE_URL = 'https://kantora19.ge';
export const APP_TITLE_LANG = {en: 'Kantora 19', ka: 'კანტორა 19'};
export const PAGE_TITLES = {
  MY_ORDERS: {en: 'My Orders', ka: 'ჩემი შეკვეთები'},
  TERMS: {en: 'Terms and Conditions', ka: 'წესები და პირობები'},
  CONFIDENTIAL: {en: 'Confidentiality policy', ka: 'კონფიდენციალურობის პოლიტიკა'},
  CONTACT: {en: 'Contact', ka: 'კონტაქტი'},
  HOW_IT_WORKS: {en: 'How it works', ka: 'როგორ მუშაობს'}
};
export const SERVER_BASE_URL = environment.SERVER_BASE_URL;
export const PRINTER_URL = environment.PRINTER_URL;
export const NOTIFICATION_SERVER_PUBLIC_KEY = environment.NOTIFICATION_SERVER_PUBLIC_KEY;
export const SERVER_API_URL = `${SERVER_BASE_URL}`;
export const MIDDLE_SERVER_URL = environment.MIDDLE_BASE_URL;
export const SERVER_SOCKET_URL = `${SERVER_BASE_URL}/socket`;
export const SERVER_SOCKET_RECONNECT_INTERVAL = 1000;
export const SERVER_API_IMAGE_URL = `${SERVER_API_URL}/files/`;
export const OAUTH2_URL = `${SERVER_BASE_URL}/oauth2/authorization`;
export const OAUTH_REDIRECT_URL = `?redirect_uri=${environment.OAUTH_REDIRECT_URL}`;
export const OAUTH2_FACEBOOK_URL = `${OAUTH2_URL}/facebook${OAUTH_REDIRECT_URL}`;
export const OAUTH2_GOOGLE_URL = `${OAUTH2_URL}/google${OAUTH_REDIRECT_URL}`;
export const PAYMENT_TYPES: Array<{ label: string, key: string, enabled: boolean }> = [
  {label: 'Cash', key: 'CASH', enabled: true},
  {label: 'Terminal', key: 'TERMINAL', enabled: true},
  {label: 'Card', key: 'CARD', enabled: true}
];
export const PAYMENT_TYPE_MAP = {
  CASH: {label: 'Cash', key: 'CASH', enabled: true},
  TERMINAL: {label: 'Terminal', key: 'TERMINAL', enabled: true},
  CARD: {label: 'Card', key: 'CARD', enabled: false}
};
export const ORDER_TYPES = [
  {label: OrderType.DELIVERY, key: OrderType.DELIVERY, enabled: true},
  {label: OrderType.TAKE_AWAY, key: OrderType.TAKE_AWAY, enabled: true},
  {label: OrderType.ON_SPOT, key: OrderType.ON_SPOT, enabled: true}
];
export const DEFAULT_DELIVERY_PRICE = 2;
export const DEFAULT_ON_SPOT_FEE = 10;

export const SSR = {
  products: {
    GET_ALL: {
      key: 'PRODUCTS_GET_ALL'
    }
  },
  productTypes: {
    GET_ALL: {
      key: 'PRODUCT_TYPES_GET_ALL'
    }
  },
  settings: {
    GET_ALL: {
      key: 'SETTINGS_GET_ALL'
    }
  }, metas: {
    GET_ALL: {
      key: 'METAS_GET_ALL'
    }
  },
  translation: {
    ALL_STATIC: {
      key: 'TRANSLATION_ALL_STATIC_GET_ALL'
    }
  },
  language: {
    ALL_ACTIVE: {
      key: 'LANGUAGE_ALL_GET_ALL_ACTIVE'
    }
  }
};
