import {SERVER_API_IMAGE_URL} from '../app.constants';

export class AppUtils {
  public static image(image): string {
    if (image && image.length > 30) {
      return image;
    }
    if (image) {
      return SERVER_API_IMAGE_URL + image;
    }
  }
}

export const remove = (array: Array<any>, filter: (it: any) => boolean): Array<any> => {
  const index = array.findIndex(filter);
  array.splice(index, 1);
  return array;
};

