import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SidenavContent, SidenavModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SidenavService implements OnDestroy {

  public sideNavComp = new SidenavModel(null, false);
  private sideNavSubject = new BehaviorSubject<SidenavModel>(this.sideNavComp);
  public sidenav$ = this.sideNavSubject.asObservable();


  constructor() {
  }

  public changeContent(content: SidenavModel): void {
    this.sideNavSubject.next(content);
  }

  public closSideNav(): void {
    this.sideNavSubject.next({open: false});
  }

  public openSideNav(): void {
    this.sideNavSubject.next({open: true});
  }

  private toComponent(content: SidenavContent): void {
    this.sideNavSubject.next({content});
  }

  public toCart(): void {
    this.toComponent(SidenavContent.CART);
  }

  public toCheckout(): void {
    this.toComponent(SidenavContent.CHECKOUT);
  }

  public toLogin(): void {
    this.toComponent(SidenavContent.LOGIN);

  }

  public toSignUp(): void {
    this.toComponent(SidenavContent.SIGNUP);

  }

  public toProfile(): void {
    this.toComponent(SidenavContent.USER_PROFILE);
  }

  ngOnDestroy(): void {
    this.sideNavSubject.complete();
  }

}
