import {ICartEntry} from './CartEntry';
import {User} from './User';


export enum OrderStatus {
  NEW = 'NEW',
  SEEN = 'SEEN',
  GETTING_READY = 'GETTING_READY',
  READY = 'READY',
  IN_THE_WAY = 'IN_THE_WAY',
  DELIVERED = 'DELIVERED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED'
}

export enum OrderType {
  DELIVERY = 'DELIVERY',
  ON_SPOT = 'ON_SPOT',
  TAKE_AWAY = 'TAKE_AWAY',
}

export enum OrderStatusPublic {
  NEW = 'NEW',
  GETTING_READY = 'GETTING_READY',
  READY = 'READY',
  IN_THE_WAY = 'IN_THE_WAY',
  DELIVERED = 'DELIVERED',
  REJECTED = 'REJECTED',
}

export interface IOrder {
  id?: number;
  entries?: ICartEntry[];
  address?: string;
  phone?: string;
  paymentType?: string;
  orderType?: OrderType;
  addFee?: boolean;
  fee?: number;
  feePercent?: number;
  tableNumber?: number;
  numberOfGuests?: number;
  bond?: number;
  region?: string;
  regionId?: number;
  status?: OrderStatus;
  comment?: string;
  deliveryPrice?: number;
  orderDate?: Date;
  author?: User;
  priceSum?: number;
  orderEntrySum?: number;
  discount?: number;
  discountValue?: number;
  billPrinted?: boolean;
  kitchenChequePrinted?: boolean;
}

export class Order implements IOrder {
  constructor(
    public id?: number,
    public entries?: ICartEntry[],
    public paymentType?: string,
    public orderType?: OrderType,
    public addFee?: boolean,
    public fee?: number,
    public feePercent?: number,
    public bond?: number,
    public address?: string,
    public phone?: string,
    public region?: string,
    public status?: OrderStatus,
    public deliveryPrice?: number,
    public tableNumber?: number,
    public numberOfGuests?: number,
    public comment?: string,
    public author?: User,
    public priceSum?: number,
    public orderEntrySum?: number,
    public discount?: number,
    public discountValue?: number
  ) {

  }
}
