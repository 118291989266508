export interface ILanguage {
  id?: number;
  name?: string;
  locale?: string;
  isDefault?: boolean;
  isActive?: boolean;
}

export class Language implements ILanguage {
  constructor(
    public id?: number,
    public locale?: string,
    public name?: string,
    public isDefault?: boolean,
    public isActive?: boolean,
    public selected?: boolean
  ) {
  }

}
