import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ConfirmDialogComponent} from './cofirm-dialog/confirm-dialog.component';
import {DeliveryRowComponent} from './delivery-row/delivery-row.component';
import {AppShellNoRenderDirective} from './directives/app-shell-norender.directive';
import {AppShellRenderDirective} from './directives/app-shell-render.directive';
import {OrderExpansionComponent} from './order-expansion/order-expansion.component';
import {OrderFilterComponent} from './order-filter/order-filter.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';


@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DeliveryRowComponent,
    OrderFilterComponent,
    AppShellRenderDirective,
    AppShellNoRenderDirective,
    PageNotFoundComponent,
    OrderExpansionComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    ConfirmDialogComponent,
    DeliveryRowComponent,
    OrderFilterComponent,
    PageNotFoundComponent,
    OrderExpansionComponent,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    AppShellNoRenderDirective,
    AppShellRenderDirective
  ]
})
export class SharedModule {
}
