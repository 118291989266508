import {KPaging} from '../../models';
import {HttpParams} from '@angular/common/http';

export class HttpUtils {
  public static getHttpParams(paging: KPaging, filter: any): HttpParams {
    let params = new HttpParams();
    if (paging) {
      for (const [key, value] of Object.entries(paging)) {
        if (value === 0 || value) {
          params = params.append(key, value.toString());
        }
      }
    }
    if (filter) {
      for (const [key, value] of Object.entries(filter)) {
        if (!value) {
          continue;
        }
        if (value && value instanceof Date) {
          params = params.append(key, value.toDateString());
        } else if (typeof value === 'object') {
          params = params.append(key, encodeURIComponent(JSON.stringify(value)));
        } else if (value) {
          params = params.append(key, value.toString());
        }
      }
    }
    return params;
  }
}
