import {Product} from './Product';
import {IProductDetail} from './productDetail';

export interface ICartEntry {
  id?: number;
  productId?: number;
  productDetailIds?: number[];
  details?: IProductDetail[];
  product?: Product;
  quantity: number;
  price?: number;
  comment?: string;
  newProduct?: boolean;
}

export class CartEntry implements ICartEntry {
  constructor(
    public quantity: number,
    public productDetailIds: number[] = [],
    public id?: number,
    public productId?: number,
    public price?: number,
    public comment?: string
  ) {

  }
}

