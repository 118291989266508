import {ProductDetail} from './productDetail';
import {ProductType} from './ProductType';

export interface IProduct {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
  price?: number;
  productType?: ProductType;
  priority?: number;
  productDetails?: ProductDetail[];
  showOnFront?: boolean;
  urlPath?: string;
}

export class Product implements IProduct {

  public static countPrice(product: IProduct): number {
    let detailsSum = 0;
    for (const item of product.productDetails) {
      if (item.checked) {
        detailsSum += item.price;
      }
    }
    return detailsSum + product.price;
  }

  public static countDetailsPrice(details: ProductDetail[]): number {
    let detailsSum = 0;
    details.forEach(item => {
      if (item.checked) {
        detailsSum += item.price;
      }
    });
    return detailsSum;
  }

  public static countAllDetailsPrice(details: ProductDetail[]): number {
    let detailsSum = 0;
    details.forEach(item => {
      detailsSum += item.price;
    });
    return detailsSum;
  }

  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public image?: string,
    public productType?: ProductType,
    public price?: number,
    public priority?: number,
    public showOnFront?: boolean,
    public productDetails?: ProductDetail[],
    public urlPath?: string,
  ) {
  }
}
