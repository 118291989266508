import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({cookieName: 'XSRF-TOKEN', headerName: 'XSRF-TOKEN'})
  ]
})
export class CoreModule {
}
