import {Injectable} from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IPrintResponse} from '../../models';

const BASE_URL = SERVER_API_URL + '/cheque';


@Injectable({
  providedIn: 'root'
})
export class ChequeService {

  constructor(private http: HttpClient) {
  }


  getBill(locale: string, id: number): Observable<IPrintResponse> {
    return this.http.get<IPrintResponse>(`${BASE_URL}/bill/${locale}/${id}`);
  }

  getKitchenCheque(locale: string, id: number): Observable<IPrintResponse> {
    return this.http.get<IPrintResponse>(`${BASE_URL}/kitchen/${locale}/${id}`);
  }

  updateStatus(orderId: number, billPrinted?: boolean, kitchenChequePrinted?: boolean): Observable<any> {
    return this.http.post(`${BASE_URL}/update-status`, {orderId, billPrinted, kitchenChequePrinted});
  }

}
