import {Injectable} from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {KPaging, Order, PageResponse} from '../../models';
import {Observable} from 'rxjs';
import {IDatasourceService} from './k-datasource.datasource';
import {HttpUtils} from './httpUtils';

const BASE_ORDER_URL = SERVER_API_URL + '/order';


@Injectable({
  providedIn: 'root'
})
export class MyOrdersService implements IDatasourceService<Order> {

  constructor(private http: HttpClient) {
  }

  search(paging: KPaging, filter: any): Observable<PageResponse<Order>> {
    return this.http.get<PageResponse<Order>>(`${BASE_ORDER_URL}/my-orders`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }
}
