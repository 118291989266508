import {ILanguage} from "./Language";

export interface ITranslation {
  id?: number,
  keyword?: string,
  language?: ILanguage,
  translation?: string,
  isStatic?: boolean
}

export class Translation implements ITranslation {
  constructor(
    public keyword?: string,
    public language?: ILanguage,
    public translation?: string,
    public id?: number,
    public isStatic?: boolean,
  ) {
  }

}
