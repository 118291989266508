import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PRINTER_CASH, PRINTER_KITCHEN} from '../../models';
import {AppSettingsService} from './app-settings.service';
import {ChequeService} from './cheque.service';
import {LanguageService} from './language.service';
import {OrdersService} from './orders.service';
import {mergeMap} from 'rxjs/operators';
import {Principal} from './auth/principal.service';

const PRINT_URL = 'http://localhost:8099/print';
const HEALTH_URL = 'http://localhost:8099/health';
const PRINTERS_LIST_URL = 'http://localhost:8099/printers';

const PRINT_WIDTH = 620;
const PRINT_HEIGHT = 780;

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  constructor(private http: HttpClient,
              private settingsService: AppSettingsService,
              private chequeService: ChequeService,
              private orderService: OrdersService,
              private languageService: LanguageService,
              private principal: Principal
  ) {
  }


  checkHealthCash(): Observable<{ status, health }> {
    const printerName = this.settingsService.getPrinterName(PRINTER_CASH);
    return this.http.get<{ status, health }>(HEALTH_URL, {params: {printerName}});
  }

  getPrinterList(): Observable<Array<string>> {
    return this.http.get<Array<string>>(PRINTERS_LIST_URL);
  }

  printCash(locale: string, orderId: number): void {
    this.principal.isAdmin().then(isAdmin => {
      if (isAdmin) {
        this.doPrint(orderId, PRINTER_CASH, locale)
          .subscribe(() => {
            console.log(`Cheque printed successfully`);
          }, error => {
            console.error(`Cheque print failed:  ${error}`);
          });
      }
    });
  }

  printKitchen(orderId: number, locale?: string): void {
    let lang;
    if (!locale) {
      lang = this.languageService.defaultLanguage?.locale;
    } else {
      lang = locale;
    }
    this.principal.isAdmin().then(isAdmin => {
      if (isAdmin) {
        this.doPrint(orderId, PRINTER_KITCHEN, lang)
          .subscribe(() => {
            console.log(`Cheque printed successfully`);
          }, error => {
            console.error(`Cheque print failed:  ${error}`);
          });
      }
    });
  }

  doPrint(orderId: number, printer: string, lang?: string): Observable<any> {
    return this.checkHealthCash()
      .pipe(
        mergeMap(health => {
          if (health.status === 1) {
            if (printer === PRINTER_CASH) {
              return this.chequeService.getBill(lang, orderId);
            } else {
              return this.chequeService.getKitchenCheque(lang, orderId);
            }
          }
          throw Error('Printer Health check Failed');
        }),
        mergeMap(cheque => this.executePrint(cheque.html, printer)),
        mergeMap(printResp => {
          console.log({PrintStatus: printResp.status});
          if (printResp.status === 1) {
            if (printer === PRINTER_CASH) {
              return this.chequeService.updateStatus(orderId, true, undefined);
            } else {
              return this.chequeService.updateStatus(orderId, undefined, true);
            }
          } else {
            throw Error('Cheque Print failed');
          }
        })
      );
  }

  executePrint(html: string, printer: string): Observable<{ status }> {
    if (!html || html.length === 0) {
      return new Observable<{ status; }>(subscriber => {
        subscriber.next({status: 0});
      });
    }
    const printerName = this.settingsService.getPrinterName(printer);
    return this.http.post<{ status }>(PRINT_URL, {
      html, width: PRINT_WIDTH, height: PRINT_HEIGHT, printerName
    });
  }
}
