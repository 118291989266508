import {CartEntry, ICartEntry, IOrder} from '../models';
import {IProduct, Product} from '../models';
import {remove} from './app.utils';

export const addCartEntry = (product: IProduct, quantity: number, comment: string, entries: ICartEntry[]): void => {
  const entry = entries.find(({product: entryProd}: ICartEntry) => {
    return cartEntryEquals(product, entryProd);
  });
  let id: any;
  if (entry && stringEquals(entry.comment.trim(), comment.trim())) {
    entry.quantity += quantity;
    quantity = entry.quantity;
    removeCartEntry(entry, entries);
    id = entry.id;
  } else {
    id = undefined;
  }
  entries.unshift({id, product, quantity, comment: comment?.trim(), newProduct: true});
};

export const updateCartEntry = (entry: ICartEntry, entries: ICartEntry[]): void => {
  const en = entries.find((centry) => {
    return entry.product.id === centry.product.id;
  });
  en.quantity = entry.quantity;
};

export const removeCartEntry = (entry: ICartEntry, entries: ICartEntry[]): void => {
  remove(entries, cEntry => cartEntryEquals(entry.product, cEntry.product));
};

export const countEntriesSum = (entries: ICartEntry[]): number => {
  let sum = 0;
  entries.forEach(entry => {
    if (entry.product) {
      sum += entry.quantity * Product.countPrice(entry.product);
    } else if (entry.price) {
      sum += entry.price;
    }
  });
  return sum;
};


export const getOrderEntriesToPost = (entries: ICartEntry[]): Array<CartEntry> => {
  const entriesToPost: CartEntry[] = [];
  entries.forEach(entry => {
    const ent = new CartEntry(entry.quantity, getProdDetIds(entry), entry.id, entry.product.id, entry.price, entry.comment);
    entriesToPost.push(ent);
  });
  return entriesToPost;
};

export const getProdDetIds = (entry: ICartEntry): number[] => {
  const prodDetIds = [];
  for (const item of entry.product.productDetails) {
    if (item.checked) {
      prodDetIds.push(item.id);
    }
  }
  return prodDetIds;
};

/*Helper*/
export const cartEntryEquals = (product1: IProduct, product2: IProduct): boolean => {
  if (product1.id !== product2.id) {
    return false;
  }
  const prodDetsChecked1 = product1.productDetails.filter(e => e.checked);
  const prodDetsChecked2 = product2.productDetails.filter(e => e.checked);
  if (prodDetsChecked1.length !== prodDetsChecked2.length) {
    return false;
  }
  for (const item of prodDetsChecked1) {
    let exists = false;
    for (const item1 of prodDetsChecked2) {
      if (item.name === item1.name) {
        exists = true;
      }
    }
    if (!exists) {
      return false;
    }
  }
  return true;
};
export const equalsBool = (checked: boolean, checked2: boolean): boolean => {
  return getBool(checked) === getBool(checked2);
};
export const stringEquals = (str1: string, str2: string): boolean => {
  if (str1 && str2) {
    return str1.trim() === str2.trim();
  } else {
    if (str1) {
      return false;
    }
    return !str2;
  }
};

export const getBool = (check): boolean => {
  return !!check;
};
export const formatDetailName = (name: string): string => {
  if (name.length > 30) {
    return name.substr(1, 30) + '...';
  }
  return name;
};



