import {ICartEntry} from './CartEntry';

export interface ICart {
  deliveryPrice: number;
  addFee: boolean;
  entries?: ICartEntry[];
  comment: string;
}

export class Cart implements ICart {
  constructor(
    public deliveryPrice: number,
    public addFee: boolean = true,
    public comment: string,
    public entries?: ICartEntry[]
  ) {

  }
}
