import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {APP_BASE_URL, SERVER_API_URL, SSR} from '../../app.constants';
import {KPaging, PageResponse, SeoMeta} from '../../models';
import {HttpUtils} from './httpUtils';
import {IDatasourceService} from './k-datasource.datasource';
import {LanguageService} from './language.service';
import {SsrService} from './ssr.service';

const BASE_META_URL = SERVER_API_URL + '/meta';


@Injectable({
  providedIn: 'root'
})
export class SeoMetaService implements IDatasourceService<SeoMeta> {
  private metas: {};

  constructor(
    private http: HttpClient,
    private ssrService: SsrService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private dom: Document
  ) {
    this.loadMetas().subscribe(() => {
      console.log('******** Metas Loaded********');
    });
  }


  updateMetas(params: { title: string, setCanonical, metas?: [{ key, content }] }) {
    if (params.setCanonical)
      this.createCanonicalURL();
    this.titleService.setTitle(params?.title);
    this.updateLanguageTag();

    this.loadMetas().subscribe(metas => {
      metas.forEach(meta => {
        this.updateMeta(meta.key, meta.value);
      });
    });
    params.metas?.forEach(meta => {
      this.updateMeta(meta.key, meta.content);
    })
  }

  createCanonicalURL() {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    const path = this.router.url.indexOf('?') > 0 ?
      this.router.url.substring(0, this.router.url.indexOf('?')) :
      this.router.url;
    link.setAttribute('href', `${APP_BASE_URL}${path}`);
  }

  private updateLanguageTag() {
    const language = this.languageService.getLanguage();
    this.dom.documentElement.lang = language?.locale;
  }

  private updateMeta(name, content) {
    this.metaService.updateTag({
      name,
      content
    });
  }

  update(seoMeta: SeoMeta): Observable<any> {
    return this.http.post(BASE_META_URL, seoMeta);
  }

  search(paging: KPaging, filter: any): Observable<PageResponse<SeoMeta>> {
    return this.http.get<PageResponse<SeoMeta>>(`${BASE_META_URL}/search`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }

  loadMetas(): Observable<any> {
    if (this.metas) {
      return of(this.metas);
    }
    return this.ssrService.usingTransferState(SSR.metas.GET_ALL.key, () => {
      return this.http.get(BASE_META_URL)
        .pipe(
          tap(resp => {
            this.metas = {};
            resp.forEach(meta => {
              this.metas[meta.key] = meta;
            });
          })
        );
    });
  }

  getMeta(key: string): SeoMeta {
    if (!this.metas) {
      this.loadMetas();
      return null;
    }
    return this.metas[key];
  }
}
