import {Injectable} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PlatformService} from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class SsrService {
  private cache = {};

  constructor(private transferState: TransferState,
              private platformService: PlatformService) {
  }

  public usingTransferState<T>(key: string, httpCall: () => Observable<T>): Observable<T> {
    const STATE_KEY = makeStateKey<T>(key);
    if (this.platformService.isPlatformServer() && this.transferState.hasKey(STATE_KEY)) {
      const response = this.transferState.get(STATE_KEY, null);
      return of(response);
    } else if (this.cache[key]) {
      return of(this.cache[key]);
    }
    return httpCall()
      .pipe(
        tap(response => {
          if (this.platformService.isPlatformServer()) {
            this.transferState.set(STATE_KEY, response);
          } else {
            this.cache[key] = response;
          }
        })
      );
  }
}
