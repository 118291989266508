import {Injectable} from '@angular/core';
import {SERVER_API_URL, SSR} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {ITranslation, KPaging, PageResponse, Translation} from '../../models';
import {Observable} from 'rxjs';
import {IDatasourceService} from './k-datasource.datasource';
import {HttpUtils} from './httpUtils';
import {LocalStorageService} from './local-storage.service';
import {TransferState} from '@angular/platform-browser';
import {PlatformService} from './platform.service';
import {SsrService} from './ssr.service';

const BASE_ACC_URL = SERVER_API_URL + '/translation';


@Injectable({
  providedIn: 'root'
})
export class TranslationService implements IDatasourceService<Translation> {
  translationCallCount = 0;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private transferState: TransferState,
    private platformService: PlatformService,
    private ssrService: SsrService
  ) {
  }

  private translations: Translation[];

  search(paging: KPaging, filter: any): Observable<PageResponse<Translation>> {
    return this.http.get<PageResponse<Translation>>(`${BASE_ACC_URL}/search`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }

  getAllStatic(): Observable<Translation[]> {
    return this.ssrService.usingTransferState(SSR.translation.ALL_STATIC.key,
      () => this.http.get<Translation[]>(`${BASE_ACC_URL}/all-static`)
    );
  }

  add(lang: ITranslation): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/add`, lang);
  }

  delete(id: number): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/delete/${id}`, {});
  }

  loadTranslations(): void {
    this.translationCallCount++;
    this.getAllStatic().subscribe(res => {
      this.translations = res;
      this.localStorage.setItem('static-translations', JSON.stringify(res));
    });
  }

  public getTranslation(keyword): string {
    if (!keyword) {
      return '';
    }
    if (!this.translations) {
      this.translations = JSON.parse(this.localStorage.getItem('static-translations'));
      if (
        !this.translations
        && this.platformService.isPlatformBrowser()
        && this.translationCallCount < 5
      ) {
        this.loadTranslations();
      }
    }
    if (this.translations) {
      const trans: Translation = this.translations.find(tr => tr.keyword === keyword);
      if (trans) {
        return trans.translation;
      }
    }
    return 'translation not found ' + keyword;
  }
}
