import {Injectable, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterModule, Routes} from '@angular/router';
import {IOrder, User, UserRole} from './models';
import {UserRouteAccessService} from './core/guard/user-route-access-service';
import {Principal} from './core/services/auth/principal.service';
import {LanguageResolver} from './core/resolvers/language.resolver';
import {LanguageAccessService} from './core/guard/language-access-service';
import {PageNotFoundComponent} from "./shared/page-not-found/page-not-found.component";


@Injectable({providedIn: 'root'})
export class UserResolve implements Resolve<User> {
  constructor(private principal: Principal) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<IOrder> {
    return this.principal.getUser();
  }
}


const routes: Routes = [
  {
    path: ':lang/admin',
    data: {
      authorities: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
    },
    resolve: {
      language: LanguageResolver
    },
    canActivate: [UserRouteAccessService, LanguageAccessService],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },

  {
    path: ':lang',
    canActivate: [LanguageAccessService],
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    resolve: {
      language: LanguageResolver
    }
  },
  {
    path: '**',
    canActivate: [LanguageAccessService],
    component: PageNotFoundComponent
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
