import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {IProduct, KPaging, PageResponse, Product} from '../../models';
import {HttpUtils} from './httpUtils';
import {IDatasourceService} from './k-datasource.datasource';
import {SsrService} from './ssr.service';

const BASE_ACC_URL = SERVER_API_URL + '/product';


@Injectable({
  providedIn: 'root'
})
export class ProductsService implements IDatasourceService<Product> {

  constructor(private http: HttpClient, private ssrService: SsrService) {
  }

  find(id: number): Observable<Product> {
    return this.http.get<Product>(`${BASE_ACC_URL}/${id}`);
  }

  search(paging: KPaging, filter: any): Observable<PageResponse<IProduct>> {
    return this.searchProducts(paging, filter);
  }

  searchProducts(paging: KPaging, filter: any): Observable<PageResponse<IProduct>> {
    return this.http.get<PageResponse<IProduct>>(`${BASE_ACC_URL}/search`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }

  getAll(): Observable<PageResponse<Product>> {
    return this.ssrService.usingTransferState('PRODUCTS_GET_ALL', // will cache on keyword only for Platform=Server
      () => this.http.get<PageResponse<Product>>(`${BASE_ACC_URL}/get-all`)
    );
  }

  add(product: IProduct): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/add`, product);
  }

  editProduct(product: Product): Observable<any> {
    return this.http.post(BASE_ACC_URL, product);
  }

  updateProductPriority(productId: number, priority: number): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/priority`, {productId, priority});
  }

  updateProductShowOnFront(productId: number, showOnFront: boolean): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/show-on-front`, {productId, showOnFront});
  }

  delete(id: number): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/delete/${id}`, {});
  }
}
