import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IProduct, KPaging, ProductType} from '../../models';
import {finalize, map} from 'rxjs/operators';
import {ProductsService} from './products.service';
import {PlatformService} from './platform.service';


@Injectable({
  providedIn: 'root'
})
export class ProductLoadService {
  private productTypeSubj = new BehaviorSubject<ProductType>(null);
  public productType$: Observable<ProductType> = this.productTypeSubj.asObservable();
  private productsSubject = new BehaviorSubject<IProduct[]>([]);
  private products$: Observable<IProduct[]> = this.productsSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private products: IProduct[];
  paging: KPaging = {
    sortingColumn: 'priority',
    sortOrder: 'asc',
    pageNumber: 0,
    pageSize: 100
  };
  filter = {
    filter: '',
    productTypeId: null,
  };

  constructor(
    private productsService: ProductsService,
    private platformService: PlatformService,
  ) {
    this.watchProdTypeId();
  }


  public loadProducts() {
    this.loadingSubject.next(true);
    this.productsService.getAll().pipe(
      finalize(() => this.loadingSubject.next(false)),
      map(resp => resp.content)
    ).subscribe(content => {
      this.productsSubject.next(content);
      this.products = content;
      this.loadingSubject.next(false);
    });
  }

  private filterProducts(type) {
    if (this.products) {
      this.loadingSubject.next(true);
      const filtered = this.products.filter((prod) => prod.productType.id === type.id || type.allType);
      this.productsSubject.next(filtered);
      this.loadingSubject.next(false);
    }
  }

  private watchProdTypeId() {
    this.productType$.subscribe(type => {
      if (type) {
        this.filter.productTypeId = type.id;
        this.filterProducts(type);
      }
    });
  }

  public updateProductType(type) {
    this.productTypeSubj.next(type);
  }

  public watchProducts() {
    this.loadProducts();
    return this.products$;
  }

  public watchLoading() {
    return this.loading$;
  }
}
