export const ACTIVE_HOURS = 'ACTIVE_HOURS';
export const DELIVERY_PRICE = 'DELIVERY_PRICE';
export const ON_SPOT_FEE = 'ON_SPOT_FEE';
export const PRINTER_CASH = 'PRINTER_CASH';
export const PRINTER_KITCHEN = 'PRINTER_KITCHEN';

export class AppParameter {
  constructor(
    public id?: number,
    public key?: string,
    public value?: string,
    public type?: string,
  ) {

  }

}
