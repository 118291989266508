import {Injectable} from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {IOrder, Order} from '../../models';
import {Observable} from 'rxjs';
import {IDatasourceService} from './k-datasource.datasource';
import {KPaging} from '../../models';
import {HttpUtils} from './httpUtils';
import {PageResponse} from '../../models';

const BASE_ACC_URL = SERVER_API_URL + '/order';


@Injectable({
  providedIn: 'root'
})
export class OrdersService implements IDatasourceService<Order> {

  constructor(private http: HttpClient) {
  }

  findOrder(id: number): Observable<Order> {
    return this.http.get<Order>(`${BASE_ACC_URL}/${id}`);
  }

  search(paging: KPaging, filter: any): Observable<PageResponse<Order>> {
    return this.http.get<PageResponse<Order>>(`${BASE_ACC_URL}/search`, {
      params: HttpUtils.getHttpParams(paging, filter)
    });
  }

  countSum(filter: any): Observable<any> {
    return this.http.get<Order[]>(`${BASE_ACC_URL}/count-sum`, {
      params: HttpUtils.getHttpParams(null, filter)
    });
  }

  addOrder(order: IOrder): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/add`, order);
  }

  updateOrder(order: IOrder): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/update`, order);
  }

  editOrder(order: Order): Observable<any> {
    return this.http.post(BASE_ACC_URL, order);
  }

  deleteOrder(id: number): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/delete/${id}`, {});
  }

  updateStatus(orderId: number, status: string, comment: string): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/change-status`, {orderId, status, comment});
  }

  updatePaymentType(orderId: number, paymentType: string): Observable<any> {
    return this.http.post(`${BASE_ACC_URL}/change-payment-type`, {orderId, paymentType});
  }

  getOrdersToPrint(): Observable<Array<number>> {
    return this.http.get<Array<number>>(`${BASE_ACC_URL}/to-print`);
  }
}
