import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {APP_TITLE, SERVER_SOCKET_URL} from '../../app.constants';
import {Title} from '@angular/platform-browser';

import * as SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';


const SOCKET_URL = SERVER_SOCKET_URL;

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  private notificationSubject = new BehaviorSubject<any>(null);
  public notifications$ = this.notificationSubject.asObservable();
  public connected = false;
  private stompClient = null;
  private audio;
  private titleChangeInterval;
  private audioInterval;
  public lastNotification;
  isBrowser: boolean;


  constructor(
    private titleService: Title,
    private router: Router,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.audio = new Audio();
      this.audio.src = '../../assets/audio/credulous.mp3';
      this.audio.load();
    }
  }

  public subscribeSocketNotifications(userId) {
    this.connected = true;
    if (this.stompClient && this.stompClient.connected) {
      return;
    }
    this.stompClient = Stomp.over(() => {
      return new SockJS(SOCKET_URL);
    });

    this.stompClient.connect({}, () => {
      this.stompClient.subscribe(`/user/${userId}/notification`, (message) => {
        const notification = JSON.parse(message.body);
        this.lastNotification = notification;
        this.notificationSubject.next(notification);
        this.startNotificationActions(notification);
      });
    });
    return this.notifications$;
  }

  public unsubscribeSocketNotifications() {
    if (this.stompClient !== null) {
      console.log('Socket connection closed!');
      this.stompClient.disconnect();
    }
    this.connected = false;
  }

  public stopNotificationActions() {
    this.stopNotifications();
  }

  private startNotificationActions(notification) {
    this.notifyAudio();
    this.notifyInTitle(notification);
  }


  private notifyAudio() {
    if (this.audioInterval) {
      return;
    }
    this.playAudio();
    this.audioInterval = setInterval(() => {
      this.playAudio();
    }, 1000 * 60);
  }

  private playAudio() {
    this.audio.play();
    setTimeout(() => {
      this.audio.play();
    }, 2000);
  }

  private notifyInTitle(notification) {
    if (this.titleChangeInterval) {
      return;
    }
    this.titleChangeInterval = setInterval(() => {
      this.titleService.setTitle(notification.message);
      setTimeout(() => this.titleService.setTitle(APP_TITLE), 1000);
    }, 2000);
  }

  private stopNotifications() {
    clearInterval(this.audioInterval);
    clearInterval(this.titleChangeInterval);
    this.audioInterval = undefined;
    this.titleChangeInterval = undefined;
  }

  ngOnDestroy(): void {
    this.notificationSubject.complete();
  }

}
