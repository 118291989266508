import {Injectable} from '@angular/core';
import {User, UserRole} from '../../models';
import {Principal} from './auth/principal.service';
import {NotificationService} from './notification.service';
import {OrdersService} from './orders.service';
import {PrinterService} from './printer.service';

@Injectable({
  providedIn: 'root'
})
export class WatchService {
  private _watchingOrders = false;
  private _watchingOrdersInterval;
  private _loggedInUser: User;

  constructor(
    private _notificationService: NotificationService,
    private _orderService: OrdersService,
    private _printerService: PrinterService,
    private _principal: Principal
  ) {
    this.watchPrincipal();
  }

  public watchOrdersToPrint() {
    if (!this._watchingOrders) {
      this._watchingOrders = true;
      console.log(`*********** watching orders to print**********`);
      this._watchingOrdersInterval = setInterval(() => this.checkOrdersToPrint(), 1000 * 10);
    } else if (!this.isAdmin() && this._watchingOrdersInterval) {
      clearInterval(this._watchingOrdersInterval);
    }
  }

  private watchPrincipal(): void {
    this._principal.identity(true).then((user) => {
      this._loggedInUser = user;
      this.connectToNotifications();
      this.watchOrdersToPrint();
    });
    this._principal.getAuthenticationState().subscribe((user) => {
      console.log('Auth state change');
      this._loggedInUser = user;
      this.connectToNotifications();
      this.watchOrdersToPrint();
    });
  }

  private connectToNotifications(): void {
    if (this.isAdmin()) {
      if (!this._notificationService.connected) {
        this._notificationService.subscribeSocketNotifications(this._loggedInUser.id);
      }
      return;
    }
    this._notificationService.unsubscribeSocketNotifications();
  }

  private isSuperAdmin(): boolean {
    return this._loggedInUser && this._loggedInUser.role === UserRole.SUPER_ADMIN;
  }

  private isAdmin(): boolean {
    return (this._loggedInUser && this._loggedInUser.role === UserRole.ADMIN) || this.isSuperAdmin();
  }

  private checkOrdersToPrint() {
    this._orderService.getOrdersToPrint().subscribe(orders => {
      orders.forEach(orderId => {
        this._printerService.printKitchen(orderId);
      });
      console.log({OrdersToPrint: orders});
    });
  }
}
