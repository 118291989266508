import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {SERVER_API_URL} from '../../app.constants';
import {Inject, Injectable} from '@angular/core';
import {LocalStorageService} from '../services/local-storage.service';
import {PlatformService} from '../services/platform.service';
import {DOCUMENT} from '@angular/common';
import {LanguageService} from '../services';


export const EXCLUSIONS = ['/language/all-active'];

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(
    private localStorage: LocalStorageService,
    private platformService: PlatformService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.skip(request)) {
      return next.handle(request);
    }
    let lang;
    if (this.platformService.isPlatformBrowser()) {
      lang = this.getLang(window);
    } else {
      lang = this.getLang(this.document);
    }
    if (!lang) {
      lang = this.languageService.getLanguage()?.locale;
    }
    if (!lang) {
      lang = this.languageService?.defaultLanguage?.locale;
    }
    if (lang) {

      request = request.clone({
        setHeaders: {
          language: lang,
        }
      });
    }
    return next.handle(request);
  }

  private getLang(platform) {
    const pathSplit = platform.location.pathname.split('/');
    if (pathSplit.length > 1) {
      return pathSplit[1];
    }
  }

  private skip(request: HttpRequest<any>) {
    const isDomesticUrl = (SERVER_API_URL && request.url.startsWith(SERVER_API_URL));
    const isExcluded = EXCLUSIONS.filter(ex => request.url.includes(ex)).length > 0;
    return !request || !request.url || (!isDomesticUrl || isExcluded);
  }
}
