import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LanguageService} from '../services';
import {Location} from '@angular/common';


@Injectable({providedIn: 'root'})
export class LanguageAccessService implements CanActivate {
  constructor(
    private _router: Router,
    private _languageService: LanguageService,
    private _location: Location
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      const langFromPath = route.params.lang;
      this._languageService.getAllActive().subscribe(languages => {
        let selected = languages.find(lg => lg.locale === langFromPath);
        if (!selected) {
          selected = this._languageService.getLanguage();
          if (!selected) {
            selected = languages.find(lang => lang.isDefault);
          }
          this._languageService.setLanguage(selected);
          const pathSplit = this._location.path().split('?');
          const newPath = `/${selected.locale}${pathSplit[0]}`;
          this._router.navigate([newPath], {
            queryParams: this.getQueryParams(pathSplit),
            replaceUrl: true
          }).then();
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  private getQueryParams(pathSplit: string[]) {
    if (pathSplit.length <= 1) {
      return {};
    }
    const queryParamSplit = pathSplit[1].split('&');
    return queryParamSplit.map(qParam => {
      const split = qParam.split('=');
      const result = {};
      result[split[0]] = split[1];
      return result;
    }).reduce((prev, cur) => Object.assign(prev, cur), {});
  }

}
