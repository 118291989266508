import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Cart, ICartEntry, IProduct, OrderType} from '../../models';
import {addCartEntry, cartEntryEquals, countEntriesSum, removeCartEntry, updateCartEntry} from '../../utils/cart.utils';
import {AppSettingsService} from './app-settings.service';
import {LocalStorageService} from './local-storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslationService} from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart: Cart = new Cart(this.deliveryPrice(), true, '', []);
  private cartSubject = new BehaviorSubject<Cart>(this.cart);
  private cartSumSubject = new BehaviorSubject<number>(0);
  public cart$ = this.cartSubject.asObservable();
  public cartSum$ = this.cartSumSubject.asObservable();
  public orderType: OrderType = OrderType.DELIVERY;
  public sum = 0;

  constructor(private settingsService: AppSettingsService,
              private localStorageService: LocalStorageService,
              private snackBar: MatSnackBar,
              private translationService: TranslationService) {
    this.loadFromLocalStorage();
  }

  public getCart(): Cart {
    return this.cart;
  }

  public addEntry(product: IProduct, quantity: number, comment: string): void {
    addCartEntry(product, quantity, comment, this.cart.entries);
    this.cartSubject.next(this.cart);
    this.countSum();
    this.updateLocalStorage();
    this.openSnackBar(this.translationService.getTranslation('prod.added.in.cart'), '');
  }


  public removeEntry(entry: ICartEntry) {
    removeCartEntry(entry, this.cart.entries);
    this.cartSubject.next(this.cart);
    this.countSum();
    this.updateLocalStorage();
  }

  public changeEntrySize(entry: ICartEntry, increase) {
    // entry = this.cart.entries.find(cEntry => cartEntryEquals(entry.product, cEntry.product));
    if (entry.quantity + increase < 1) {
      return;
    }

    entry.quantity = entry.quantity + increase;
    this.cartSubject.next(this.cart);
    this.countSum();
    this.updateLocalStorage();
  }


  public updateEntry(entry: ICartEntry) {
    updateCartEntry(entry, this.cart.entries);
    this.cartSubject.next(this.cart);
    this.countSum();
    this.updateLocalStorage();
  }

  public countSum() {
    this.sum = countEntriesSum(this.cart.entries);
    const deliveryPrice = this.orderType === OrderType.DELIVERY ? this.settingsService.getDeliveryPrice() : 0;
    const onSpotFee = this.orderType === OrderType.ON_SPOT && this.cart.addFee ? (this.sum * this.onSpotFeePercent() / 100) : 0;
    this.sum = this.sum + deliveryPrice + onSpotFee;
    this.cartSumSubject.next(this.sum);
  }

  public deliveryPrice() {
    return this.settingsService.getDeliveryPrice();
  }

  public onSpotFeePercent() {
    return this.settingsService.getOnSpotFee();
  }

  public onSpotFeeValue() {
    const initialSum = this.cart.addFee ? (this.sum * 100 / (100 + this.onSpotFeePercent())) : this.sum;
    const val = this.orderType === OrderType.ON_SPOT ? (initialSum * this.onSpotFeePercent() / 100) : 0;
    return Math.round(val * 100) / 100;
  }

  public setAddFeeFlag(addFee: boolean) {
    this.cart.addFee = addFee;
    this.cartSubject.next(this.cart);
    this.countSum();
  }

  reset() {
    this.cart = new Cart(this.deliveryPrice(), true, '', []);
    this.cartSubject.next(this.cart);
    this.countSum();
    this.clearLocalStorage();
  }

  updateLocalStorage() {
    this.localStorageService.setItem('cart', JSON.stringify(this.cart));
  }

  clearLocalStorage() {
    this.localStorageService.removeItem('cart');
  }

  loadFromLocalStorage() {
    const stringCart = this.localStorageService.getItem('cart');
    if (stringCart && stringCart.length > 5) {
      this.cart = JSON.parse(this.localStorageService.getItem('cart'));
      this.cart.addFee = true;
      this.cart.deliveryPrice = this.deliveryPrice();
      this.cartSubject.next(this.cart);
      this.countSum();
    }
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 300,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
