import {Injectable} from '@angular/core';
import {SERVER_API_URL, SSR} from '../../app.constants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ILanguage, Language} from '../../models';
import {LocalStorageService} from './local-storage.service';
import {SsrService} from './ssr.service';
import {tap} from 'rxjs/operators';

const BASE_ACC_URL = SERVER_API_URL + '/language';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private default: Language;

  constructor(
    private http: HttpClient,
    private ssrService: SsrService,
    private localStorage: LocalStorageService
  ) {
  }

  setLanguage(lang: Language) {
    this.localStorage.setItem('language', JSON.stringify(lang));
  }

  getLanguage(): Language {
    const lang = JSON.parse(this.localStorage.getItem('language'));
    this.fixLang(lang);
    if (!lang) {
      return this.defaultLanguage;
    }
    return lang;
  }

  public get defaultLanguage(): Language {
    return this.default;
  }

  findLanguage(id: number): Observable<Language> {
    return this.http.get<Language>(`${BASE_ACC_URL}/${id}`);
  }

  getAll(): Observable<Language[]> {
    return this.http.get<Language[]>(`${BASE_ACC_URL}/all`);
  }

  getAllActive(): Observable<Language[]> {
    return this.ssrService.usingTransferState(SSR.language.ALL_ACTIVE.key,
      () => this.http.get<Language[]>(`${BASE_ACC_URL}/all-active`))
      .pipe(
        tap(languages => {
          languages.forEach(lang => {
            if (lang.isDefault) {
              this.default = lang;
            }
          });
        })
      );
  }


  addLanguage(lang: ILanguage) {
    return this.http.post(`${BASE_ACC_URL}/add`, lang);
  }

  deleteLanguage(id: number) {
    return this.http.post(`${BASE_ACC_URL}/delete/${id}`, {});
  }

  private fixLang(lang) {
    if (lang) {
      this.getAllActive().subscribe(languages => {
        const fromDb = languages.find(language => lang.locale === language.locale);
        if (!fromDb) {
          this.setLanguage(this.defaultLanguage);
        }
      });
    }
  }
}
