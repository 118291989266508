import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {SERVER_API_URL} from '../../app.constants';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';


@Injectable()
export class XSRFInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url) && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
      return next.handle(request);
    }
    if (!['GET', 'HEAD', 'OPTIONS'].includes(request.method)) {
      const token = this.cookieService.get('XSRF-TOKEN');
      request = request.clone({
        setHeaders: {
          'x-xsrf-token': token
        }
      });
    }
    return next.handle(request);
  }
}
