export interface IProductType {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
  allType?: boolean;
  urlPath?: string;
}

export class ProductType {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public image?: string,
    public allType?: boolean,
    public urlPath?: string
  ) {
  }

}
