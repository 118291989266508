import {KPaging} from './paging';

export enum StatisticType {
  DAILY,
  MONTHLY,
  ANNUAL
}
export enum StatisticPoint {
  ORDER,
  PRODUCT,
  USER
}

export interface IStatisticsRequest {
  type: StatisticType;
  point: StatisticPoint;
  orderFilter: any;
  productFilter: any;
  paging?: KPaging;
}


