import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements Storage {
  storage = {};
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  [name: string]: any;

  readonly length: number;

  clear(): void {
    if (this.isBrowser) {
      return sessionStorage.clear();
    }
    this.storage = {};
  }

  getItem(key: string): string | null {
    if (this.isBrowser) {
      return sessionStorage.getItem(key);
    }
    return this.storage[key] ? this.storage[key] : null;
  }

  key(index: number): string | null {
    if (this.isBrowser) {
      return sessionStorage.key(index);
    }
    return undefined;
  }

  removeItem(key: string): void {
    if (this.isBrowser) {
      return sessionStorage.removeItem(key);
    }
    delete this.storage[key];
  }

  setItem(key: string, value: string): void {
    if (this.isBrowser) {
      return sessionStorage.setItem(key, value);
    }
    this.storage[key] = value;
  }
}
